<template>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i :class="icon" />
        </div>
        <div>
          {{heading}}
          <div class="page-title-subheading">{{subheading}}</div>
        </div>
      </div>
      <div class="page-title-actions">
        <SearchBox :hint=searchHint />
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SearchBox from "./Header/SearchBox";

library.add(faStar, faPlus);

export default {
  components: {
    SearchBox,
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    icon: String,
    heading: String,
    subheading: String,
    searchHint: String
  }
};
</script>
