<template>
  <div>
    <page-title :heading=heading :subheading="subheading" :icon=icon></page-title>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10" >
          <div class="main-card mb-lg-4 card">
            <div class="card-body">
              <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)"/>
              <p class="m-4" v-if="panels.data.length === 0">Няма намерени резултати</p>
              <div v-else class="pl-3">
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  <div class="vertical-timeline-element" v-for="panel in panels.data" v-bind:key="panel.id">
                    <div>
                  <span class="vertical-timeline-element-icon bounce-in ml-2">
                    <i class="badge badge-dot badge-dot-xl" :style="'background-color:' + panel.color"></i>
                  </span>
                      <div class="vertical-timeline-element-content bounce-in pl-1">
                        <div class="widget-content p-0">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-heading">
                                {{panel.title}}
                              </div>
                              <div class="widget-subheading">
                                <div>
                                  Начало: {{formatDate(panel.start)}}
                                </div>
                                <div>
                                  Край: {{formatDate(panel.end)}}
                                </div>
                                <div>
                                  Локация: {{ panel.venue.title }} {{ (panel.venue.venue_note !== "") ? "-" : ""}} {{ panel.venue.venue_note }}
                                </div>
                              </div>
                            </div>
                            <div class="widget-content-right">
                              <div class="d-inline-block">
                                <b-dropdown toggle-class="btn-icon btn-icon-only" right variant="link" no-caret>
                                    <span slot="button-content">
                                      <i class="pe-7s-menu btn-icon-wrapper"></i>
                                    </span>
                                  <ul class="nav flex-column">
                                    <li class="nav-item">
                                      <router-link :to="{ name: 'panel-polls', params: { eventId: selectedEventId, targetId: panel.id } }"
                                                   class="nav-link" style="color: var(--primary)">
                                        <font-awesome-icon icon="poll" class="mr-1"/>
                                        Гласуване
                                      </router-link>
                                    </li>
                                    <li class="nav-item">
                                      <router-link :to="{ name: 'panel-qna', params: { eventId: selectedEventId, targetId: panel.id } }"
                                                   class="nav-link" style="color: var(--primary)">
                                        <font-awesome-icon icon="comments" class="mr-1"/>
                                        Въпроси и отговори
                                      </router-link>
                                    </li>
                                  </ul>
                                </b-dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="vertical-timeline-element-date">
                          <p class="m-0" align="center">{{formatDate(panel.start)}}</p>
                          <p class="m-0" align="center">{{formatTime(panel.start)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-block text-right card-footer">
              <button class="btn btn-success btn-lg" v-on:click="$refs.PanelCreate.showAddPanel(selectedEventId)">
                Добавяне
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination v-if="panels.total>0"
        id="pagination"
        align="center"
        :total-rows="panels.total"
        @input="changePage()"
        v-model="currentPage"
        :per-page="panels.per_page">
    </b-pagination>
  </div>
</template>


<script>
import PageTitle from "../../Layout/Components/PageTitleWithBackButton.vue";
import VueElementLoading from "vue-element-loading";
import PanelsService from "../../services/panels.service";
import swal from "sweetalert";
import CountryFlag from "vue-country-flag";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faEdit,
  faUsers,
  faUsersCog,
  faArrowLeft,
  faLanguage,
  faPoll,
    faComments
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Multiselect from 'vue-multiselect';
import moment from "moment";
import {minValue, required} from "vuelidate/lib/validators";
import { ToggleButton } from "vue-js-toggle-button";

import emitter from "../../common/emitter";
import ProfileService from "../../services/profile.service";

library.add(
    faEdit,
    faUsers,
    faUsersCog,
    faArrowLeft,
    faLanguage,
    faPoll,
    faComments
);

export default {
  components: {
    PageTitle,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
    Multiselect,
    CountryFlag,
    quillEditor,
    ToggleButton,
  },

  data: () => ({
    currentUser: {},

    heading: 'Панели',
    subheading:"",
    icon: 'pe-7s-note2 icon-gradient bg-happy-itmeo',

    showLoading: false,
    selectedEventId: -1,

    panels: {
      data: []
    },
    currentPage: 1,

    languages: [],
  }),

  mounted() {
    this.selectedEventId = this.$route.params.eventId;
    this.currentPage = this.$store.state.panels.page
    this.getList();
  },

  methods:
      {
        resetFields() {
          this.panels = {
            data: []
          };
          this.currentPage = 1;
        },

        getList() {
          this.showLoading = true;

          this.currentUser = ProfileService.getLocal();
          if(this.currentUser == null) {
            ProfileService.getRemote().then((response) =>
            { response
              this.currentUser = ProfileService.getLocal();
            })
          }

          PanelsService.getList(this.selectedEventId, this.$store.state.panels.page).then((response) =>
              { response
                this.panels = response.data.response.results;
                var eventTitle = response.data.response.event_title;
                this.subheading = "Събитие \"" + eventTitle + "\"";

                for (const panel of this.panels.data) {
                  if (panel.hasOwnProperty('translations')) {
                    if (panel.translations.length === 1) {
                      panel.title = panel.translations[0].title;
                      panel.venue.venue_note = panel.translations[0].venue_note;
                    } else {
                      for (const translation of panel.translations) {
                        if (translation.language_id === panel.main_language_id) {
                          panel.title = translation.title;
                          panel.venue.venue_note = translation.venue_note;
                        }
                      }
                    }
                  }

                  if (panel.venue.translations.length === 1) {
                    panel.venue.title = panel.venue.translations[0].title;
                  } else {
                    for (const translation of panel.venue.translations) {
                      if (translation.language_id === panel.venue.main_language_id) {
                        panel.venue.title = translation.title;
                      }
                    }
                  }
                }

                this.showLoading = false
              },
              error => {
                this.showLoading = false
                this.handeError(error);
              })
        },

        refreshList()
        {
          this.$store.dispatch('panels/refreshPage')
          this.getList();
        },

        changePage() {
          this.$store.dispatch('panels/changePage', this.currentPage)
          this.getList()
        },

        closeModal() {
          this.$root.$emit('bv::toggle::modal', 'modal-panels', '#btnToggle');
        },

        formatDate(date)
        {
          return moment(date).utcOffset('+0000').format('DD.MM.yy')
        },

        formatTime(date)
        {
          return moment(date).utcOffset('+0000').format('HH:mm')
        },

        handeError(error)
        {
          let errorMsg =
              error.message || (error.response && error.response.data && error.response.data.message) ||
              error.toString();
          if(error.response.data.error.message)
          {
            swal("Грешка", error.response.data.error.message, "error");
          }
          if (error.response && error.response.status === 500)
          {
            console.log(errorMsg)
          }
        }
      },
}
</script>

<style>
.vertical-timeline::before {
  left: 75px;
}
</style>