<template>
  <div>
    <page-title3 :heading=heading :subheading=subheading :icon=icon :search-hint=searchHint></page-title3>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10" >
          <div class="main-card mb-lg-4 card">
            <div class="card-header">
              <b-button v-if="!filtered" v-b-toggle.filter variant="outline-link" class="pl-1 pr-1">
                <font-awesome-icon icon="filter" class="mr-1"/>Филтър
              </b-button>
              <b-button v-if="filtered" v-b-toggle.filter v-on:click="clearFilter" variant="outline-link" class="pl-1 pr-1">
                <font-awesome-icon icon="filter" class="mr-1"/>
                <span class="text-danger">Изчисти филтъра</span>
              </b-button>
              <div class="btn-actions-pane-right actions-icon-btn">
                <v-btn-toggle>
                  <b-button variant="outline-link" :disabled="view == 'calendar'" v-on:click="showCalendar()">
                    <font-awesome-icon icon="calendar-alt"/>
                  </b-button>
                  <b-button variant="outline-link" :disabled="view == 'list'" v-on:click="showList()">
                    <font-awesome-icon icon="bars"/>
                  </b-button>
                </v-btn-toggle>
              </div>
            </div>
            <div class="card-body">
              <b-collapse ref="filter" id="filter" accordion="filter-accordion" role="tabpanel">
                <b-card-body class="p-0">
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="search" type="text" label="Търсене..." hide-details class="mt-0 pt-0"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" md="3">
                        <v-select v-model="filterStatus" :items="statuses" label="Статус" class="p-0 m-0"></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mb-1 mt-0">
                      <dates-filter :start-date="startDate" :end-date="endDate"
                                    @startDateChanged="updateStartDate" @endDateChanged="updateEndDate"/>
                      <v-col cols="12" sm="6" md="3" class="pl-3">
                        <span class="mr-5">Публичен</span>
                        <toggle-button :labels="true" :sync="true" v-model="filterPublic">
                          <template slot="checked">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                          </template>
                          <template slot="unchecked">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                          </template>
                        </toggle-button>
                      </v-col>
                      <v-col sm="12" md="3" align="right">
                        <b-button variant="info" class="btn-sm" v-on:click="filterList">
                          <font-awesome-icon class="mr-2" icon="filter" />Филтрирай
                        </b-button>
                        <b-button variant="danger" class="btn-sm" :hidden="!filtered" v-b-toggle.filter v-on:click="clearFilter">
                          <font-awesome-icon class="mr-2" icon="times" />Изчисти
                        </b-button>
                      </v-col>
                    </v-row>
                  </v-form>
                </b-card-body>
              </b-collapse>
              <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)"/>
              <div class="m-4" v-if="events.data.length === 0 && view !== 'calendar'">
                <p>Няма намерени резултати</p>
              </div>
              <div v-if="view == 'list'">
                <ul class="todo-list-wrapper list-group list-group-flush" v-for="event in events.data" v-bind:key="event.id">
                  <li class="list-group-item">
                    <div class="todo-indicator" v-bind:class="getStatusColor(event.status)">
                    </div>
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left" v-on:click="getEvent(event.id)">
                          <v-avatar color="grey">
                            <img width="42" :src="imageHostname+'/storage/images/events/'+event.main_photo" :alt="event.title" :hidden="!hasPhoto(event.main_photo)" />
                            <span class="white--text headline" :hidden="hasPhoto(event.main_photo)" >{{event.title.substring(0,1).toUpperCase()}}</span>
                          </v-avatar>
                        </div>
                        <div class="widget-content-left ml-3">
                          <div class="widget-heading">
                            <button title="Преглед" v-on:click="getEvent(event.id)">
                              {{event.title}}
                            </button>
                          </div>
                          <div class="widget-subheading">
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Начало: {{ formatFullDate(offsetDate(event.start)) }}
                            </h6>
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Край: {{ formatFullDate(offsetDate(event.end)) }}
                            </h6>
                            <h6 class="font-size-md font-weight-normal">
                              Локации: {{ getEventVenues(event.venues) }}
                            </h6>
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <b-dropdown toggle-class="btn-icon btn-icon-only" right variant="link" no-caret>
                            <span slot="button-content">
                              <i class="pe-7s-menu btn-icon-wrapper"></i>
                            </span>
                            <ul class="nav flex-column">
                              <li class="nav-item">
                                <router-link :to="{ name: 'panels', params: { eventId: event.id } }" class="nav-link" style="color: var(--primary)">
                                  <font-awesome-icon icon="list-alt" class="mr-1"/>
                                  Панели
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'event-polls', params: { targetId: event.id } }"
                                             class="nav-link" style="color: var(--primary)">
                                  <font-awesome-icon icon="poll" class="mr-1"/>
                                  Гласуване
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'event-qna', params: { targetId: event.id } }"
                                             class="nav-link" style="color: var(--primary)">
                                  <font-awesome-icon icon="comments" class="mr-1"/>
                                  Въпроси и отговори
                                </router-link>
                              </li>
                            </ul>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="view == 'calendar'" >
                <v-layout>
                  <v-flex>
                    <v-sheet>
                      <v-toolbar flat>
                        <v-toolbar-title class="mr-auto ml-auto">
                          <v-btn fab text small color="grey darken-2" @click="prev">
                            <font-awesome-icon icon="arrow-left" class="mr-1"/>
                          </v-btn>
                          {{ calendarTitle }}
                          <v-btn fab text small color="grey darken-2" @click="next">
                            <font-awesome-icon icon="arrow-right" class="mr-1"/>
                          </v-btn>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-calendar
                        ref="calendar"
                        v-model="selectedDate"
                        type="month"
                        color="var(--primary)"
                        :events="calendarEvents"
                        :now="selectedDate"
                        :value="selectedDate"
                        :event-more="false"
                        event-more-text="{0} скрити"
                        event-start="startDate"
                        event-end="endDate"
                        :event-color="event.color"
                        :weekdays="weekdays"
                        locale="bg"
                        :show-month-on-first="false"
                        @click:event="showEvent"
                        @change="updateRange"
                        style="min-height: 500px">
                        <template v-slot:event="{event}">
                          <div :style="{color:'white'}" class="fill-height pl-2">
                              <v-icon x-small :class="getStatusTextColor(event.status)"
                                      style="border: 1px solid white; border-radius: 50%">mdi-circle</v-icon>
                            {{ event.title }}
                          </div>
                        </template>
                      </v-calendar>
                      <v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          max-width="350px"
                      >
                        <v-card
                            color="grey lighten-4"
                            width="350px"
                        >
                          <v-toolbar
                              :color="selectedEvent.color"
                              dark
                          >
                            <v-btn icon x-small class="bg-white ml-1">
                              <v-icon :class="getStatusTextColor(selectedEvent.status)">mdi-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title v-html="selectedEvent.title"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <b-dropdown toggle-class="btn-icon btn-icon-only" variant="link" right no-caret>
                            <span slot="button-content" style="color: white">
                              <i class="pe-7s-menu btn-icon-wrapper"></i>
                            </span>
                              <ul class="nav flex-column">
                                <li class="nav-item">
                                  <router-link :to="{ name: 'panels', params: { eventId: selectedEvent.id } }" class="nav-link" style="color: var(--primary)">
                                    <font-awesome-icon icon="list-alt" class="mr-1"/>
                                    Панели
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link :to="{ name: 'event-polls', params: { targetId: selectedEvent.id } }"
                                               class="nav-link" style="color: var(--primary)">
                                    <font-awesome-icon icon="poll" class="mr-1"/>
                                    Гласуване
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link :to="{ name: 'event-qna', params: { targetId: selectedEvent.id } }"
                                               class="nav-link" style="color: var(--primary)">
                                    <font-awesome-icon icon="comments" class="mr-1"/>
                                    Въпроси и отговори
                                  </router-link>
                                </li>
                              </ul>
                            </b-dropdown>
                          </v-toolbar>
                          <v-card-text style="min-height: 350px">
                            <span v-html="selectedEvent.description"></span>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                            >
                              Затвори
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination v-if="view == 'list' && events.total>0"
        id="pagination"
        align="center"
        :total-rows="events.total"
        @input="changePage()"
        v-model="currentPage"
        :per-page="events.per_page">
    </b-pagination>

    <!--Modals-->
    <!--View-->
    <b-modal id="modal-view" title="Описание"  no-body>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Име/Заглавие</label>
        <p class="">{{ event.title }}</p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Описание</label>
        <p v-html="event.description"></p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Начало</label>
        <p class="">{{ formatFullDate(event.start) }}</p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Край</label>
        <p class="">{{ formatFullDate(event.end) }}</p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Статус</label>
        <p v-if="event.status === 0" class="">Неактивен</p>
        <p v-else-if="event.status === 1" class="">Предстоящ</p>
        <p v-else-if="event.status === 2" class="">Текущ</p>
        <p v-else-if="event.status === 3" class="">Приключил</p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Показан публично</label>
        <p v-if="event.is_public === 0" class="">Не</p>
        <p v-else-if="event.is_public === 1" class="">Да</p>
      </div>
      <div class="position-relative form-group">
        <label class="font-weight-bold">Добавен от</label>
        <p class="">{{ event.created_by_name }}</p>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()">
          Затвори
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import PageTitle3 from "../Layout/Components/PageTitle3.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EventsService from "../services/events.service"
import VueElementLoading from "vue-element-loading";
import CountryFlag from "vue-country-flag";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faListAlt,
  faEdit,
  faCamera,
  faLocationArrow,
  faMap,
  faMapMarked,
  faUsers,
  faUsersCog,
  faFlag,
  faCircle,
  faLanguage,
  faTrash,
  faStar,
  faCalendarAlt,
  faBars,
  faFilter,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faPaperPlane,
  faChartLine,
  faPoll,
    faComments
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import emitter from "../common/emitter";
import EventPanels from './Events/EventPanels.vue';
import EventUsers from './Events/EventUsers.vue';
import EventStatistics from "./Events/EventStatistics";

import {faStar as farStar} from "@fortawesome/free-regular-svg-icons";
import { ToggleButton } from "vue-js-toggle-button";
import ProfileService from "../services/profile.service";
import swal from "sweetalert";
import DateHelper from "../util/dateHelper";
import DatesFilter from "../Layout/Components/DatesFilter";

library.add(
    faListAlt,
    faEdit,
    faCamera,
    faLocationArrow,
    faMap,
    faMapMarked,
    faUsers,
    faUsersCog,
    faFlag,
    faCircle,
    faLanguage,
    faTrash,
    faStar,
    farStar,
    faBars,
    faFilter,
    faCalendarAlt,
    faArrowLeft,
    faArrowRight,
    faTimes,
    faPaperPlane,
    faChartLine,
    faPoll,
    faComments
);

export default {
  components: {
    DatesFilter,
    PageTitle3,
    VuePerfectScrollbar,
    VueElementLoading,
    CountryFlag,
    quillEditor,
    'font-awesome-icon': FontAwesomeIcon,
    ToggleButton,
    EventPanels,
    EventUsers,
    EventStatistics
  },
  mixins: [DateHelper],
  data: () => ({
    imageHostname: Vue.prototype.$hostnameImages,
    currentUser: {},

    heading: 'Събития',
    subheading: 'Събития, на които сте модератор',
    icon: 'pe-7s-display2 icon-gradient bg-happy-itmeo',
    searchHint: "Име, описание",
    currentPage: 1,
    events: {
      data: []
    },
    calendarEvents: [],
    event: [],
    showLoading: false,
    view: "list",
    search: "",
    startDate: "",
    endDate: "",
    calendarStartDate: "",
    calendarEndDate: "",
    filterStatus: -1,
    filterPublic: false,
    filtered: false,
    statuses: [
      {text: "Всички", value: -1},
      {text: "Неактивен", value: 0},
      {text: "Предстоящ", value: 1},
      {text: "Текущ", value: 2},
      {text: "Приключил", value: 3}
    ],

    // today: new Date().toISOString().slice(0,10),
    selectedDate: new Date().toISOString().slice(0,10),
    statusColors:[
      'bg-secondary',
      'blue',
      'bg-success',
      'bg-danger'
    ],
    statusTextColors:[
      'text-secondary',
      'blue',
      'text-success',
      'text-danger'
    ],
    colors_bgnd:[
      "bg-primary",
      "bg-secondary",
      "bg-success",
      "bg-danger",
      "bg-info",
      "bg-dark"
    ],

    calendarTitle: "",

    monthsNames:["Януари", "Февруари", "Март", "Април", "Май",
      "Юни", "Юли", "Август", "Септември", "Октомври",
      "Ноември", "Декември",
    ],
    weekdays: [1, 2, 3, 4, 5, 6, 0],

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false
  }),
  created() {
    this.getFirebaseNotifications();
  },
  mounted() {
    this.filtered = this.$store.state.events.filtered
    this.filterPublic = this.$store.state.events.public
    this.filterStatus = this.$store.state.events.status
    this.startDate = this.$store.state.events.startDate
    this.endDate = this.$store.state.events.endDate
    this.search = this.$store.state.events.search
    this.currentPage = this.$store.state.events.page
    this.view = this.$store.state.events.view

    if(this.filtered) {
      this.$root.$emit('bv::toggle::collapse', 'filter', '#btnToggle');
    }
    else if(this.search !== "") {
      emitter.emit("searchOpenRemote",this.search);
    }

    if(this.view == "calendar") {
      this.calendarStartDate = this.$store.state.events.calendarStartDate;
      this.calendarEndDate = this.$store.state.events.calendarEndDate;
      this.selectedDate = this.$store.state.events.selectedCalendarDate;

      let yr = this.calendarStartDate.year;
      let mn = this.calendarStartDate.month;

      this.calendarTitle = this.monthsNames[mn - 1] + " " + yr;

      this.getMonthList();
    }
    else {
      this.getList();
    }

    //Listen for new selected photos to add
    emitter.on("refreshList", (e) =>
    {
      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList()
      }
    });

    emitter.on("search", (e) =>
    {
      this.search = e.query;
      this.$store.dispatch('events/changeSearch', this.search)
      this.$store.dispatch('events/refreshPage')

      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList();
      }
    });
    emitter.on("searchClose", (e) =>
    {
      this.clearSearch();
    });
  },

  beforeDestroy() {
    emitter.off('refreshList');
  },

  methods:
  {
    getFirebaseNotifications()
    {
      console.log(Vue.prototype.$messaging)
      console.log("Listening...")
      try {
        Vue.prototype.$messaging.onMessage((payload) =>
        {
          console.log("//////////////message Firebase", payload);
        });
      } catch (error) {
        console.log("/////////errrr Firebase", error);
      }

    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedDate = this.formatDateForCalendar(this.selectedEvent.start);
        this.$store.dispatch('events/changeSelectedCalendarDate', this.selectedDate)
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    getList()
    {
      this.showLoading = true;
      this.currentUser = this.getUser();

      var datesError = false;
      var errorMsg = "";
      if(this.startDate !== "" || this.endDate !== "") {
        if (!this.isDateValidForApi(this.startDate) || !this.isDateValidForApi(this.endDate)) {
          datesError = true;
          errorMsg = "Моля въведете валидни дати!"
        } else if (this.isDateEndAfterStart(this.startDate, this.endDate)) {
          datesError = true;
          errorMsg = "Началната дата трябва да бъде преди крайната дата!"
        }
      }

      var filters = {};

      if(this.filtered && datesError) {
        swal("", errorMsg, "warning");
      }
      else {
        filters.startDate = this.startDate;
        filters.endDate = this.endDate;
        filters.status = this.filterStatus;
        filters.public = this.filterPublic;
      }

      EventsService.getList(this.$store.state.events.page, this.search, filters).then((response) =>
      { response
        this.events = response.data.response.results;

        for (const event of this.events.data) {
          if (event.hasOwnProperty('translations')) {
            if (event.translations.length === 1) {
              event.title = event.translations[0].title;
              event.description = event.translations[0].description;
            } else {
              for (const translation of event.translations) {
                if (translation.language_id === event.main_language_id) {
                  event.title = translation.title;
                  event.description = translation.description;
                }
              }
            }
          }
        }

        this.setDatesFilter();
        this.showLoading = false
      },
      error => {
        this.showLoading = false
        this.handeError(error)
      })
    },

    getMonthList()
    {
      this.showLoading = true;

      this.currentUser = this.getUser();

      var filters = {};
      filters.status = this.filterStatus;
      filters.public = this.filterPublic;

      var today = new Date();
      var month = today.getMonth()+1;

      var datesError = false;
      var errorMsg = "";
      if(this.startDate !== "" || this.endDate !== "") {
        if (!this.isDateValidForApi(this.startDate) || !this.isDateValidForApi(this.endDate)) {
          datesError = true;
          errorMsg = "Моля въведете валидни дати!"
        } else if (this.isDateEndAfterStart(this.startDate, this.endDate)) {
          datesError = true;
          errorMsg = "Началната дата трябва да бъде преди крайната дата!"
        }
      }

      if(this.filtered && datesError) {
        swal("", errorMsg, "warning");
      }
      else if(this.filtered) {
        if(this.startDate == "") {
          if(this.$refs.calendar) {
            filters.startDate = this.$refs.calendar.lastStart.date;
          }
          else {
            filters.startDate = today.getFullYear()+'-'+month+'-'+'01';
          }
        }
        else {
          filters.startDate = this.startDate;
        }

        if(this.endDate == "") {
          if(this.$refs.calendar) {
            filters.endDate = this.$refs.calendar.lastEnd.date;
          }
          else {
            var lastDayOfMonth = new Date(today.getFullYear(), month, 0);
            filters.endDate = today.getFullYear()+'-'+month+'-'+lastDayOfMonth.getDate();
          }
        }
        else {
          filters.endDate = this.endDate;
        }
      }
      else {
        filters.startDate = this.calendarStartDate;
        filters.endDate = this.calendarEndDate;
      }

      EventsService.getMonthList(this.search, filters).then((response) =>
      { response

        this.calendarEvents = response.data.response.results;

        for (const event of this.calendarEvents) {
          if (event.hasOwnProperty('translations')) {
            if (event.translations.length === 1) {
              event.title = event.translations[0].title;
              event.description = event.translations[0].description;
            } else {
              for (const translation of event.translations) {
                if (translation.language_id === event.main_language_id) {
                  event.title = translation.title;
                  event.description = translation.description;
                }
              }
            }
          }

          event.startDate = this.formatDateForCalendar(event.start);
          event.endDate = this.formatDateForCalendar(event.end);
          event.color = this.getRandomColor();
        }

        this.setDatesFilter();
        this.showLoading = false
      },
      error => {
        this.showLoading = false
        this.handeError(error)
      })
    },

    setDatesFilter() {
      if(this.filtered) {
        if(this.startDate !== "" || this.endDate !== "") {
          emitter.emit("setFilter");
        }
      }
    },

    showCalendar() {
      this.$store.dispatch('events/changeView', "calendar")
      this.view = 'calendar';
      this.getMonthList()
    },

    showList() {
      this.$store.dispatch('events/changeView', "list")
      this.view = 'list';
      this.refreshList()
    },

    filterList() {
      this.filtered = true;

      var filters = {};
      filters.filtered = this.filtered;
      filters.startDate = this.startDate;
      filters.endDate = this.endDate;
      filters.status = this.filterStatus;
      filters.public = this.filterPublic;
      filters.search = this.search;
      filters.page = 1;

      if(this.startDate !== "") {
        if (!this.isDateValidForApi(this.startDate)) {
          filters.startDate = "";
        }
      }

      if(this.endDate !== "") {
        if (!this.isDateValidForApi(this.endDate)) {
          filters.endDate = "";
        }
      }

      this.$store.dispatch('events/addFilters', filters)

      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList();
      }
    },

    clearFilter() {
      this.filtered = false;
      this.startDate = "";
      this.endDate = "";
      this.filterStatus = -1;
      this.filterPublic = false;
      this.search = "";
      this.selectedDate = new Date().toISOString().slice(0,10);

      emitter.emit("resetDateFilter");

      this.$store.dispatch('events/deleteFilters')

      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList();
      }
    },

    clearSearch()
    {
      emitter.emit("searchCloseRemote");
      this.search = "";

      this.$store.dispatch('events/refreshSearch')
      this.$store.dispatch('events/refreshPage')

      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList()
      }
    },

    getEvent(id)
    {
      this.showLoading = true
      EventsService.getSingle(id).then((response) =>
      { response
        this.event = response.data.response.results;

        if(this.event.hasOwnProperty('translations')) {
          if(this.event.translations.length === 1) {
            this.event.title = this.event.translations[0].title;
            this.event.description = this.event.translations[0].description.substring(0,250);
          }
          else {
            for (const translation of this.event.translations) {
              if(translation.language_id === this.event.main_language_id) {
                this.event.title = translation.title;
                this.event.description = translation.description.substring(0,250);;
              }
            }
          }
        }

        this.showLoading = false
        this.$root.$emit('bv::toggle::modal', 'modal-view', '#btnToggle')
      },
      error => {
        this.showLoading = false
        this.handeError(error)
      })
    },

    refreshList()
    {
      this.$store.dispatch('events/refreshPage')
      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList();
      }
    },

    refreshCurrentPage()
    {
      if(this.view == "calendar") {
        this.getMonthList();
      }
      else {
        this.getList();
      }
    },

    getEventVenues(venues) {
      var itemVenues = "";
      if(venues.length > 0) {
        for (var i = 0; i < venues.length; i++) {
          if(i === venues.length - 1) {
            itemVenues += venues[i].title;
          }
          else {
            itemVenues += venues[i].title + ", ";
          }
        }
      }

      return itemVenues;
    },

    hasPhoto(photo)
    {
      if(!photo)
      {
        return false
      }
      else
      {
        if(photo.endsWith(".webp"))
        {
          return true
        }
        else
        {
          return false;
        }
      }
    },

    updateRange({ start, end }) {
      this.start = start;
      this.end = end;

      this.$store.dispatch('events/changeCalendarStart', start.date)
      this.$store.dispatch('events/changeCalendarEnd', end.date)

      this.calendarStartDate = start.date;
      this.calendarEndDate = end.date;
      this.getMonthList();

      let yr = start.year;
      let mn = start.month;

      this.calendarTitle = this.monthsNames[mn - 1] + " " + yr;
    },

    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getStatusColor(status)
    {
      if(this.statusColors[status])
      {
        return this.statusColors[status];
      }
    },
    getStatusTextColor(status)
    {
      if(this.statusTextColors[status])
      {
        return this.statusTextColors[status];
      }
    },
    getRandomColor()
    {
      var rand =  Math.floor(Math.random() * this.colors_bgnd.length);
      return this.colors_bgnd[rand];
    },

    changeLoading(val) {
      this.showLoading = val;
    },

    handeError(error) {
      let errorMsg =
          error.message || (error.response && error.response.data && error.response.data.message) ||
          error.toString();
      if (error.response.data.error.message) {
        swal("Грешка", error.response.data.error.message, "error");
      }
      if (error.response && error.response.status === 500) {
        console.log(errorMsg)
      }
    },

    changePage() {
      this.$store.dispatch('events/changePage', this.currentPage)
      this.getList()
    },

    getUser() {
      var user = ProfileService.getLocal();

      if(user == null) {
        ProfileService.getRemote().then((response) =>
        { response
          user = ProfileService.getLocal();
        })
      }

      return user;
    },

    updateStartDate(startDate) {
      this.startDate = startDate;
    },

    updateEndDate(endDate) {
      this.endDate = endDate;
    },
  }
}
</script>