<template>
  <!--Edit-->
  <b-modal id="modal-stats" title="Статистика"  no-body>
    <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
    <div class="p-3">
      <ul class="rm-list-borders list-group list-group-flush">
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Уникални потребители:</div>
                <div class="widget-subheading">{{stats.unique_views}}</div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Общо прегледи:</div>
                <div class="widget-subheading">{{stats.total_views}}</div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Прегледи със статус "Предстоящо":</div>
                <div class="widget-subheading">{{stats.total_views}}</div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Прегледи със статус "Текущо":</div>
                <div class="widget-subheading">{{stats.total_running}}</div>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Прегледи със статус "Приключило":</div>
                <div class="widget-subheading">{{stats.total_ended}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button @click="cancel()">
        Отказ
      </b-button>
      <b-button variant="success" @click="validateInfo()">
        Запази промените
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import StatisticsService from "../../services/statistics.service";
import moment from "moment";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon
  },
  data: () => ({
    stats: {},
    showLoading: false,

  }),

  methods:
  {
    getStats(id)
    {
      this.showLoading = true

      StatisticsService.getEventStats(id).then((response) =>
      {
        response

        this.stats = response.data.response.results;

        this.$root.$emit('bv::toggle::modal', 'modal-stats', '#btnToggle')
      })

      this.showLoading = false
    },
  }
}
</script>