x<template>
  <div>
    <page-title :heading=heading :icon=icon></page-title>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10" >
          <div class="main-card mb-lg-4 card">
            <div class="card-body">
              <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
              <p class="m-4" v-if="users.data.length === 0">Няма намерени резултати</p>
              <div v-else>
                <div class="mb-3">
                  <ul class="todo-list-wrapper list-group list-group-flush" v-for="user in users.data" v-bind:key="user.id">
                    <li class="list-group-item">
                      <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left">
                            <div class="widget-heading">
                              {{user.name}}
                            </div>
                            <div class="widget-subheading">
                              <div>
                                {{user.email}}
                              </div>
                            </div>
                          </div>
                          <div class="widget-content-right" >

                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-block text-right card-footer">
              <button class="btn btn-success btn-lg" v-on:click="showAddUser()">
                Добавяне
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination v-if="users.total>0"
        id="pagination"
        align="center"
        :total-rows="users.total"
        @input="getUsers(usersCurrentPage)"
        v-model="usersCurrentPage"
        :per-page="users.per_page">
    </b-pagination>

    <!--Add users-->
    <b-modal id="modal-add-users" :title="modalTitle" no-body>
      <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
      <v-form ref="form" lazy-validation>
        <b-tabs pills card v-model="selectedTab">
          <b-tab title="Търсене">
            <multiselect v-model="selectedUsers" placeholder="Търсене..." label="name" track-by="id"
                         :options="newUsers" :custom-label="customLabel" :blockKeys="['Delete']"
                         @search-change="onUsersSearch" :multiple="true" :show-no-results="true"
                         :close-on-select="false">
              <template slot="option" slot-scope="props">
                <div>
                  <p class="option__name">{{ props.option.name }}</p>
                  <p class="option__email">{{ props.option.email }}</p>
                </div>
              </template>
              <span slot="noResult">{{ msg }}</span>
              <span slot="noOptions">{{ msg }}</span>
            </multiselect>
          </b-tab>
          <b-tab title="Импорт">
            <div class="text-center">
              <h6>За да импортирате потребители в системата, е нужно да ги качите в табличен вид под формата на CSV файл.<br><br>
                Системата разпознава име, e-mail и телефон, като първите две са задължителни за правилен импорт.<br><br>
                Системата ще изпрати автоматично мейли на всички успешно добавени потребители.<br><br>
                Можете да изтеглите примерен файл от <a href="/storage/files/example/users.csv">тук</a>
              </h6>
            </div>
            <div class="divider"></div>
            <div class="text-center">
              <input type="file" name="csv" id="csv" accept="text/csv" />
              <div class="divider"></div>
              <v-row>
                <v-col cols="8" align="left">
                  <div class="widget-content-left">
                    <div class="font-weight-bold">Рапорт при завършване на операцията</div>
                  </div>
                </v-col>
                <v-col cols="4" align="right">
                  <div class="widget-content-right">
                    <toggle-button :labels="true" :sync="true" v-model="showReport" >
                      <template slot="checked">
                        <font-awesome-icon icon="check"></font-awesome-icon>
                      </template>
                      <template slot="unchecked">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                      </template>
                    </toggle-button>
                  </div>
                </v-col>
              </v-row>
                <v-row>
                  <v-col cols="8" align="left">
                    <div class="widget-content-left">
                      <div class="font-weight-bold">Добави потребителите, които не съществуват в базата данни</div>
                    </div>
                  </v-col>
                  <v-col cols="4" align="right">
                    <div class="widget-content-right">
                      <toggle-button :labels="true" :sync="true" v-model="createUsersChoice" >
                        <template slot="checked">
                          <font-awesome-icon icon="check"></font-awesome-icon>
                        </template>
                        <template slot="unchecked">
                          <font-awesome-icon icon="times"></font-awesome-icon>
                        </template>
                      </toggle-button>
                    </div>
                  </v-col>
              </v-row>
              <br>
              <b-button variant="success" @click="importCSV()">
                Импорт на избрания CSV файл
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </v-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Затвори
        </b-button>
        <b-button variant="success" v-on:click="createUsers()">Добави</b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import PageTitle from "../../Layout/Components/PageTitleWithBackButton.vue";
import VueElementLoading from "vue-element-loading";
import EventsService from "../../services/events.service";
import swal from "sweetalert";

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Multiselect from 'vue-multiselect';
import {ToggleButton} from "vue-js-toggle-button";
import UsersService from "../../services/users.service";

library.add(
    faArrowLeft
);

export default {
  components: {
    PageTitle,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
    Multiselect,
    ToggleButton
  },

  data: () => ({
    currentUser: {},

    heading: 'Потребители',
    icon: 'pe-7s-users icon-gradient bg-happy-itmeo',

    showLoading: false,
    showReport: false,

    eventId: -1,

    users: {
      data: []
    },
    usersCurrentPage: 1,

    newUsers: [],
    selectedUsers: [],
    searchUsers: "",

    createUsersChoice: false,
    selectedTab: 0,

    modalTitle: "Добавяне на потребители",
    msg: "Моля въведете поне 3 символа.",
  }),

  mounted() {
    this.eventId = this.$route.params.eventId;
    this.getEventUsers(1);
  },

  methods:
  {
    resetFields() {
      this.users = {
        data: []
      };
      this.usersCurrentPage = 1;

      this.newUsers = [];
      this.selectedUsers = [];
      this.searchUsers = "";
      this.createUsersChoice = false;
    },

    getEventUsers(page) {
      this.resetFields();
      this.showLoading = true;

      EventsService.getUsers(this.eventId, page).then((response) =>
      { response
        this.users = response.data.response.results;
        this.showLoading = false;
      },
      error => {
        this.showLoading = false;
        this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        swal("Грешка", this.message.error.message, "error");
      })
    },

    onUsersSearch(searchUsers) {
      if(searchUsers && searchUsers.length > 2) {
        this.msg = "Няма намерени резултати.";
        this.getUsers(searchUsers)
      }
      else {
        this.newUsers = [];
        this.msg = "Моля въведете поне 3 символа.";
      }
    },

    getUsers(searchUsers) {
      this.showLoading = true;
      UsersService.searchActive(searchUsers, 1).then((response) => {
            response

            this.newUsers = response.data.response.results.data;

            this.showLoading = false
          },
          error => {
            this.showLoading = false;
            this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            swal("Грешка", this.message.error.message, "error");
          })
    },

    createUsers()
    {
      if(this.selectedUsers.length > 0)
      {
        this.showLoading = true;

        for (var i = 0; i < this.selectedUsers.length; i++) {
          //TODO: harcoded type
          EventsService.addUser(this.eventId, this.selectedUsers[i].id, 1).then((response) =>
              {
                response

              },
              error => {
                this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                swal("Грешка", this.message.error.message, "error");
              }
              );
        }

        this.showLoading = false;
        this.selectedUsers = [];
        swal("","Потребителите са добавени успешно", "success");
        this.$root.$emit('bv::toggle::modal', 'modal-add-users', '#btnToggle');
        this.getEventUsers(1);
      }
    },

    importCSV()
    {
      var formData = new FormData();
      var csvfile = document.querySelector('#csv');
      formData.append("csv", csvfile.files[0]);

      if(this.createUsersChoice) {

        this.showLoading = true;
        UsersService.import(formData).then((response) =>
            {
              response

              this.showLoading = false;
              this.importInvitations(formData);
            },
            error =>
            {
              this.showLoading = false;
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              swal("Грешка", this.message.error.message, "error");
            });
      }
      else {
        this.importInvitations(formData);
      }
    },

    importInvitations(formData) {
      this.showLoading = true;
      EventsService.importInvitationUsers(this.eventId, formData).then((response) =>
          {
            response
            let totalCount = response.data.response.count;
            let errorCount = response.data.response.error_count;
            let successCount = response.data.response.successful;
            if(errorCount>0)
            {
              var errors = ""
              errors+="Общ брой записи: "+totalCount+"\n"
              errors+="Успешно добавени: "+successCount+"\n"
              errors+="Грешки: "+errorCount+"\n"
              for(var i=0;i<errorCount;i++)
              {
                errors += response.data.response.errors[i]+"\n"
              }

              if(this.showReport)
              {
                swal("Операцията е завършена с грешки", errors, "warning");
              }
              else
              {
                swal("Готово!", "", "warning");
              }
            }
            else
            {
              var message= "Операцията е завършена без грешки.\n"
              if(successCount==1)
              {
                message+="Импортиран е един запис."
              }
              else
              {
                message+="Импортирани са "+successCount+" записа."
              }

              if(this.showReport)
              {
                swal("Готово!", message, "success");
              }
              else
              {
                swal("Готово!", "", "success");
              }
            }

            var csvfile = document.querySelector('#csv');
            csvfile.value = '';

            this.$root.$emit('bv::toggle::modal', 'modal-add-users', '#btnToggle');
            this.getEventUsers(1);
            this.showLoading = false;
          },
          error =>
          {
            this.showLoading = false;
            this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            swal("Грешка", this.message.error.message, "error");
          });
    },

    customLabel ({ name, email }) {
      return `${name} – ${email}`
    },

    showAddUser() {
      this.selectedTab = 0;
      this.selectedUsers = [];
      this.$root.$emit('bv::toggle::modal', 'modal-add-users', '#btnToggle');
    },
  }
}
</script>