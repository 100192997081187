import api from "./api";
import TokenService from "./auth/token.service";

class EventsService
{
    BASE_PATH = '/moderator/event';

    //Panels
    getList(eventId, page) {
        return api
            .get(this.BASE_PATH + "/panels",{params: {
                event_id: eventId,
                page: page
            }});
    }

    getSingle(id) {
        return api
            .get(this.BASE_PATH + '/panel/' + id);
    }
}

export default new EventsService();
