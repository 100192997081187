import api from "./api";

class StatisticsService
{
    BASE_PATH = 'admin/stats';

    getEventStats(eventId)
    {
        var params =
        {
            event_id: eventId
        }

        return api.get(this.BASE_PATH+"/event",{params});
    }
}

export default new StatisticsService();
