import api from "./api";
import TokenService from "./auth/token.service";

class EventsService
{
    BASE_PATH = '/moderator';

    getList(page, search, filters) {
        var params = {
            page: page
        }

        if(search != "") {params.q = search;}
        if(filters.startDate != null && filters.startDate != "") {params.date_start = filters.startDate;}
        if(filters.endDate != null && filters.endDate != "") {params.date_end = filters.endDate;}
        if(filters.status != -1) {params.status = filters.status;}
        if(filters.public == true) {params.is_public = 1;}

        return api
            .get(this.BASE_PATH + "/events", {params});
    }

    getMonthList(search, filters) {
        var params = {
            date_start: filters.startDate,
            date_end: filters.endDate
        }

        if(search != "") {params.q = search;}
        if(filters.status != -1) {params.status = filters.status;}
        if(filters.public == true) {params.is_public = 1;}

        return api
            .get(this.BASE_PATH + "/events/month", {params});
    }

    getSingle(id) {
        return api
            .get(this.BASE_PATH + '/event', {params: {
                id: id
            }});
    }

    //Users
    getUsers(eventId, page) {
        return api
            .get(this.BASE_PATH + '/event/users', {params: {
                    event_id: eventId,
                    page: page
                }});
    }

    searchUsers(eventId, q, page) {
        return api
            .get(this.BASE_PATH + '/event/users/search', {params: {
                    event_id: eventId,
                    q: q
                }});
    }
}

export default new EventsService();
