import moment from "moment";

export default {
    methods: {
        isDateValidForApi(date) {
            if(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(date)) {
                return true;
            }
            return false;
        },

        isDateValid(date) {
            if(/^(0[1-9]|[1-2][0-9]|3[0-1]).(0[1-9]|1[0-2]).[0-9]{4}$/.test(date)) {
                return true;
            }
            return false;
        },

        isTimeValid(time) {
            if(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
                return true;
            }
            return false;
        },

        isDateEndAfterStart(start, end) {
            return moment(end).isBefore(start);
        },

        isTimeEndAfterStart(startDate, endDate, startTime, endTime) {
            if(startDate !== "" && endDate !== "" && startDate === endDate) {
                return moment(moment(endTime, 'HH:mm')).isBefore(moment(startTime, 'HH:mm'));
            }
            else {
                return false;
            }
        },

        formatFullDate(date)
        {
            return moment(date).format('DD.MM.yy HH:mm')
        },

        formatDate(date)
        {
            return moment(date).format('DD.MM.yy')
        },

        formatTime(date)
        {
            return moment(date).format('HH:mm')
        },

        formatDateForCalendar(date)
        {
            return moment(date).utcOffset('+0000').format('YYYY-MM-DD')
        },

        offsetDate(date)
        {
            return moment(date).utcOffset('+0000')
        },
    }
}
