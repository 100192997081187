import api from "./api";
import emitter from "../common/emitter";

class UsersService
{
    BASE_PATH = 'admin/users';

    getList(page)
    {
        return api.get(this.BASE_PATH,{params: {
                page:page
            }});
    }

    getSingle(id)
    {
        return api.get(this.BASE_PATH+'/'+id);
    }

    create(data)
    {
        return api.post(this.BASE_PATH,data);
    }

    update(id,data)
    {
        return api.put(this.BASE_PATH+'/'+id,data);
    }

    resetPassword(id)
    {
        return api.post(this.BASE_PATH+'/reset/'+id);
    }

    resendInvitation(id)
    {
        return api.post(this.BASE_PATH+'/reinvite/'+id);
    }


    updateAvatar(id,formData)
    {
        return api
            .post(this.BASE_PATH+'/'+id+'/avatar',formData,
                {
                    headers:
                        {
                            'Content-Type': 'multipart/form-data'
                        }
                })
    }
    import(formData)
    {
        return api
            .post(this.BASE_PATH+'/import',formData,
                {
                    headers:
                        {
                            'Content-Type': 'multipart/form-data'
                        }
                })
    }
    search(query,page)
    {
        return api
            .get(this.BASE_PATH+'/search',{params: {
                    q:query,
                    page:page
                }});
    }

    searchActive(query,page)
    {
        return api
            .get(this.BASE_PATH+'/search/active',{params: {
                    q: query,
                    page: page
                }});
    }
}

export default new UsersService();
