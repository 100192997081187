<template>
  <v-col cols="12" md="6">
    <v-row>
      <v-col cols="12" sm="5" md="6">
        <v-text-field
            v-model="newStartDate"
            label="От дата (DD.MM.YYYY)"
            @change="updateStartCalendarDate()"
            hide-details
            class="mt-0 pt-0"
        >
          <template v-slot:append>
            <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on">mdi-calendar
                </v-icon>
              </template>
              <v-date-picker
                  v-model="newStartCalendarDate"
                  no-title
                  @input="startDateMenu = false; updateNewStartDate()"
              ></v-date-picker>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="5" md="6">
        <v-text-field
            v-model="newEndDate"
            label="До дата (DD.MM.YYYY)"
            @change="updateEndCalendarDate()"
            hide-details
            class="mt-0 pt-0">
          <template v-slot:append>
            <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on">mdi-calendar
                </v-icon>
              </template>
              <v-date-picker
                  v-model="newEndCalendarDate"
                  no-title
                  @input="endDateMenu = false; updateNewEndDate()"
              ></v-date-picker>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import DateHelper from "../../util/dateHelper";
import emitter from "../../common/emitter";
import moment from "moment";

export default {
  props:{
    startDate: String,
    endDate: String
  },
  mixins: [DateHelper],
  data:()=>({
    startDateMenu: false,
    endDateMenu: false,

    newStartDate: "",
    newEndDate: "",

    newStartCalendarDate: "",
    newEndCalendarDate: "",

    today: new Date().toISOString().slice(0,10),
  }),

  mounted() {
    if(this.startDate !== "") {
      this.newStartDate = moment(this.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
      this.newStartCalendarDate = this.startDate;
    }

    if(this.endDate !== "") {
      this.newEndDate = moment(this.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
      this.newEndCalendarDate = this.endDate;
    }

    emitter.on("setFilter", data => this.setFields());
    emitter.on("resetDateFilter", data => this.resetFields());
  },

  methods:{
    resetFields() {
      this.newStartDate = "";
      this.newEndDate = "";

      this.newStartCalendarDate = "";
      this.newEndCalendarDate = "";

      this.startDateMenu = false;
      this.endDateMenu = false;
    },

    setFields() {
      if(this.startDate !== "") {
        this.newStartDate = moment(this.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        this.newStartCalendarDate = this.startDate;
      }

      if(this.endDate !== "") {
        this.newEndDate = moment(this.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        this.newEndCalendarDate = this.endDate;
      }
    },

    updateNewStartDate() {
      this.newStartDate = moment(this.newStartCalendarDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.$emit('startDateChanged', this.newStartCalendarDate);
    },

    updateStartCalendarDate() {
      if(this.isDateValid(this.newStartDate)) {
        this.newStartCalendarDate = moment(this.newStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
      else {
        this.newStartCalendarDate = "";
      }
      this.$emit('startDateChanged', this.newStartCalendarDate);
    },

    updateNewEndDate() {
      this.newEndDate = moment(this.newEndCalendarDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.$emit('endDateChanged', this.newEndCalendarDate);
    },

    updateEndCalendarDate() {
      if(this.isDateValid(this.newEndDate)) {
        this.newEndCalendarDate = moment(this.newEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
      else {
        this.newEndCalendarDate = "";
      }
      this.$emit('endDateChanged', this.newEndCalendarDate);
    }
  }
}
</script>